import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE } from "react-device-detect";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import find from "lodash/find";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import startsWith from "lodash/startsWith";
import includes from "lodash/includes";
import isPostalCode from "validator/lib/isPostalCode";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ScaleLoader from "react-spinners/ScaleLoader";
import TableHeader from "../Headers/TableHeader";
import { setScreenToShow } from "../../../Redux/Actions/transactionDialogActions";
import {
  setAdditionalInfo,
  setExtraData,
} from "../../../Redux/Actions/transactionActions";
import { getSunpassBalance } from "../../../Utils/helperFunctions";

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "65%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 20,
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
  },
  iconButton: {
    padding: 10,
  },
  colFlexAlignCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  vertDivider: {
    height: 28,
    margin: 4,
  },
  errorText: {
    fontSize: 16,
    color: "red",
  },
});

class EnterAdditionalInfo extends Component {
  constructor(props) {
    super(props);
    this.additionalInfoInput = React.createRef();
    this.zipInput = React.createRef();
  }

  state = {
    info: "",
    zip: "",
    error: "",
    loading: false,
  };

  submitAdditionalInfo = async (e) => {
    try {
      const { t, transaction } = this.props;
      const { info, zip } = this.state;
      e.preventDefault();
      if (!info) {
        return this.setState({ error: t("errors.enterAdditionalInfo") });
      }

      const requiresZp = this.requiresZipCode();
      if (requiresZp && !isPostalCode(zip, "US"))
        return this.setState({ error: "Invalid Zip Code" });

      const isSunpass = includes(toLower(transaction.carrier), "sunpass");

      if (isSunpass) {
        this.setState({ loading: true });
        const sunpassBal = await getSunpassBalance(info);
        if (sunpassBal) this.props.setExtraData(sunpassBal);
        this.setState({ loading: false });
      }

      this.goToAmountScreen();
    } catch (error) {
      this.goToAmountScreen();
    }
  };

  goToAmountScreen = () => {
    const { info, zip } = this.state;
    const requiresZpCd = this.requiresZipCode();
    let v = info;
    if (requiresZpCd) v = `${info},${zip}`;

    this.props.setAdditionalInfo(v);
    return this.props.setScreenToShow(4);
  };

  onChangeInfo = (event) => {
    this.setState({ info: event.target.value, error: "" });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="#####"
      />
    );
  };

  onChangeZip = (event) => {
    this.setState({ zip: event.target.value, error: "" });
  };

  clearInfoInput = () => {
    this.setState({ info: "" });
    this.focusInput();
  };

  clearZipInput = () => {
    this.setState({ zip: "" });
    this.focusZipInput();
  };

  focusInput = () => {
    this.additionalInfoInput.current.focus();
  };

  focusZipInput = () => {
    this.zipInput.current.focus();
  };

  requiresZipCode = () => {
    const { transaction, products } = this.props;
    let requiresZipCde = false;

    const foundProduct =
      transaction.productID &&
      find(products, {
        ProductID: transaction.productID,
      });

    const headline =
      foundProduct && foundProduct.Headline ? foundProduct.Headline : "";
    if (startsWith(toLower(headline), "zip")) requiresZipCde = true;

    return requiresZipCde;
  };

  render() {
    const { t, classes, transaction, products } = this.props;
    let requiresZip = this.requiresZipCode();
    let additionalInfoValue = "";
    const foundProduct =
      transaction.productID &&
      find(products, {
        ProductID: transaction.productID,
      });

    if (foundProduct) additionalInfoValue = foundProduct.AdditionalInformation;

    return (
      <div style={{ marginBottom: 15 }}>
        <div className={classes.colFlexAlignCenter}>
          <TableHeader title={`${t("headers.additionalInfoRequired")}`} />
        </div>

        <form
          noValidate
          autoComplete="off"
          onSubmit={this.submitAdditionalInfo}
        >
          <div className={classes.colFlexAlignCenter}>
            <Paper
              className={classes.root}
              elevation={3}
              style={{ marginTop: 5 }}
            >
              <IconButton
                className={classes.iconButton}
                aria-label="info"
                onClick={this.focusInput}
              >
                <InfoOutlinedIcon />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder={
                  additionalInfoValue
                    ? `${additionalInfoValue}`
                    : `${t("forms.accountNumber")}`
                }
                inputProps={{ "aria-label": "additional info" }}
                onChange={this.onChangeInfo}
                value={this.state.info}
                inputRef={this.additionalInfoInput}
                autoFocus={!isIE}
                spellCheck={false}
              />

              <Divider className={classes.vertDivider} orientation="vertical" />
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="directions"
                disabled={!this.state.info}
                onClick={this.clearInfoInput}
              >
                <HighlightOffOutlinedIcon />
              </IconButton>
            </Paper>
            {requiresZip ? (
              <Paper
                className={classes.root}
                elevation={3}
                style={{ marginTop: 8 }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="zip"
                  onClick={this.focusZipInput}
                >
                  <InfoOutlinedIcon />
                </IconButton>
                <InputBase
                  className={classes.input}
                  placeholder={t("forms.zip")}
                  onChange={this.onChangeZip}
                  value={this.state.zip}
                  inputComponent={this.NumberFormatCustom}
                  inputRef={this.zipInput}
                  spellCheck={false}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />

                <Divider
                  className={classes.vertDivider}
                  orientation="vertical"
                />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="directions"
                  disabled={!this.state.zip}
                  onClick={this.clearZipInput}
                >
                  <HighlightOffOutlinedIcon />
                </IconButton>
              </Paper>
            ) : null}
            {this.state.error ? (
              <Typography
                component="h2"
                variant="h6"
                className={classes.errorText}
              >
                {this.state.error}
              </Typography>
            ) : null}
            {this.state.loading ? (
              <ScaleLoader color={"#00457b"} loading={true} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: 10 }}
                disabled={!this.state.info}
                onClick={this.submitAdditionalInfo}
              >
                {t("buttons.next")}
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

EnterAdditionalInfo.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  setExtraData: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    transaction: state.transaction,
    products: filter(state.products, { Active: true }),
  };
}

export default connect(mapStateToProps, {
  setScreenToShow,
  setAdditionalInfo,
  setExtraData,
})(withTranslation()(withStyles(styles)(EnterAdditionalInfo)));
