import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
// import axios from "axios";
import trim from "lodash/trim";
import Box from "@material-ui/core/Box";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components
import GridContainer from "../../Layout/GuestLayout/GridContainer";
import GridItem from "../../Layout/GuestLayout/GridItem";
import Card from "../../Elements/Card/Card";
import CardAvatar from "../../Elements/Card/CardAvatar";
import CardBody from "../../Elements/Card/CardBody";
import CardFooter from "../../Elements/Card/CardFooter";
import Button from "../../Elements/CustomButtonMuiKit";
import Muted from "../../Elements/Muted";
import VerifyUserDialog from "../../Cards/ManageUsers/VerifyUserDialog";
import { checkHasManageUsersRights } from "../../../Utils/helperFunctions";
import { setIsClassicView } from "../../../Redux/Actions/applicationActions";
// import CustomSwitch from "../../Elements/CustomSwitch";
// import { BASE_API_URL } from "../../../Utils/constants";
// import history from "../../../Utils/history";
import colors from "../../../Utils/colors";
import { clearTransactionState } from "../../../Redux/Actions/transactionActions";

import teamsStyle from "../../../Utils/MaterialKitThemeJss/teamStyle";
import user from "../../../Images/user.png";

const useStyles = makeStyles(teamsStyle);

function Profile({
  agent,
  agentUsers,
  isClassicView,
  setIsClassicView,
  clearTransactionState,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showVerifyUserDialog, setShowVerifyUserDialog] = React.useState(false);
  const toggleShowVerifyUserDialog = () => {
    setShowVerifyUserDialog(!showVerifyUserDialog);
  };

  // const toggleClassicView = async () => {
  //   try {
  //     clearTransactionState();
  //     let screenToGoTo = "/agent/dashboard";
  //     if (!isClassicView) screenToGoTo = "/agent/classic/dashboard";
  //     setIsClassicView(!isClassicView);
  //     const userId = trim(sessionStorage.getItem("user"));
  //     axios.put(`${BASE_API_URL}/agent/users/classic-view`, {
  //       UserID: userId,
  //       isClassicView: !isClassicView,
  //     });

  //     return history.push(screenToGoTo);
  //   } catch (error) {
  //     return true;
  //   }
  // };

  return (
    <div className="cd-section" style={{ marginTop: 8 }}>
      <div
        // className={classes.team + " " + classes.section}
        // style={{ backgroundImage: `url(${bg})` }}
        style={{ background: colors.bgGradient17 }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title} style={{ color: "#fff" }}>
                {t("links.myProfile")}
              </h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Card profile>
                <CardAvatar profile>
                  <img src={user} alt="..." />
                </CardAvatar>
                <CardBody>
                  {/* <div
                    className={classes.justifyContent}
                    style={{ marginTop: 8 }}
                  >
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={isClassicView}
                          onChange={toggleClassicView}
                          value={isClassicView}
                        />
                      }
                      label={t("headers.classicView")}
                      style={{
                        // color: "#01021e",
                        color: colors.darkBackground,
                      }}
                    />
                  </div> */}
                  <h4 className={classes.cardTitle}>{trim(agent.Company)}</h4>
                  <h4 className={classes.cardTitle}>{`ID: ${agent.ID}`}</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      {trim(agent.Contact)}
                      <br />
                      {`c: ${trim(agent.Cell)}`}
                      <br />
                      {`p: ${trim(agent.Phone)}`}
                      <br />
                      {trim(agent.email)}
                    </h6>
                  </Muted>
                  <p className={classes.description}>
                    {trim(agent.Address)}
                    <br />
                    {`${trim(agent.City)}, ${trim(agent.State)} ${trim(
                      agent.Zip
                    )}`}
                  </p>
                  <Button color="youtube" onClick={toggleShowVerifyUserDialog}>
                    <strong>{t("forms.secureYourAcct")}</strong>
                  </Button>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <h4 className={classes.cardTitle}>
                    <Box
                      color={
                        agent.status !== "Active"
                          ? "error.main"
                          : "success.dark"
                      }
                    >
                      {agent.status === "Active"
                        ? t("agent.active")
                        : t("agent.suspended")}
                    </Box>
                  </h4>
                  {agent.status !== "Active" ? (
                    <Box color="error.main">
                      <CancelRoundedIcon
                        style={{
                          fontSize: 28,
                          fontWeight: "bold",
                          paddingLeft: 3,
                        }}
                      />
                    </Box>
                  ) : (
                    <Box color="success.dark">
                      <CheckRoundedIcon
                        style={{
                          fontSize: 28,
                          fontWeight: "bold",
                          paddingLeft: 3,
                        }}
                      />
                    </Box>
                  )}
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Card profile>
                <CardBody>
                  <h4 className={classes.cardTitle}>
                    {t("headers.quickActions")}
                  </h4>
                  {/* <div
                    className={classes.justifyContent}
                    style={{ marginTop: 8 }}
                  >
                    <Button
                      color="youtube"
                      onClick={toggleClassicView}
                      style={{
                        marginBottom: 15,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      <strong>
                        {isClassicView
                          ? t("buttons.turnOffClassic")
                          : t("buttons.turnOnClassic")}
                      </strong>
                    </Button>
                  </div> */}
                  <div className={classes.justifyContent}>
                    <Button
                      color="primary2"
                      component={RouterLink}
                      to="/agent/payments"
                    >
                      {agent.BillingType === "Prepaid"
                        ? t("buttons.addFunds")
                        : t("buttons.makeAPayment")}
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      color="primary2"
                      component={RouterLink}
                      to="/agent/rechargereport"
                    >
                      {t("buttons.rechargeReport")}
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      color="primary2"
                      component={RouterLink}
                      to="/agent/commissions"
                    >
                      {t("tables.commissions")}
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      color="primary2"
                      component={RouterLink}
                      to={
                        agent.BillingType === "Prepaid"
                          ? "/agent/depositreport"
                          : "/agent/invoices"
                      }
                    >
                      {agent.BillingType === "Prepaid"
                        ? t("buttons.depositReport")
                        : t("buttons.invoices")}
                    </Button>
                    {checkHasManageUsersRights(agentUsers) ? (
                      <Button
                        style={{ marginLeft: 8 }}
                        color="primary2"
                        component={RouterLink}
                        to="/agent/users"
                      >
                        {t("buttons.manageUsers")}
                      </Button>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <VerifyUserDialog
        showVerifyUserDialog={showVerifyUserDialog}
        closeVerifyUserDialog={toggleShowVerifyUserDialog}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    agent: state.agent,
    agentUsers: state.agentUsers,
    isClassicView: state.applicationState.isClassicView,
  };
}

export default connect(mapStateToProps, {
  setIsClassicView,
  clearTransactionState,
})(Profile);
