import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import SignaturePad from "react-signature-canvas";
import AchAutoPayPDF from "../Cards/Payments/AchAutoPayPDF";
import { SlideUp } from "../../Utils/slideTransition";
import colors from "../../Utils/colors";

import sendEmail from "../../Utils/sendEmail";
import toast from "../../Utils/toast";

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  btnArea: {
    display: "flex",
    justifyContent: "center",
    margin: `${theme.spacing(1)}px 0`,
    fontSize: 12,
    "& button": {
      //   margin: `0 ${theme.spacing(1)}px`
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& button": {
        width: "100%",
        margin: 5,
      },
    },
    position: "relative",
  },
  sigBtnArea: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: 0,
    "& button": {
      width: "100%",
      borderRadius: 0,
      "&:hover": {
        transform: "scale(1)",
      },
    },
    position: "relative",
  },
  optArea: {
    display: "flex",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  textInput: {
    color: theme.palette.primary.dark,
  },
  agreeToTermsText: {
    lineHeight: 0,
  },
  appBar: {
    position: "relative",
    background: colors.bgGradient9,
  },
  signatureTitleText: {
    flexGrow: 1,
    align: "center",
  },
  sigContainer: {
    margin: theme.spacing(3),
    backgroundColor: "#fff",
    height: "80%",
  },
  sigPad: {
    width: "100%",
    height: "100%",
  },
});

class AchAutoPayForm extends Component {
  state = {
    data: {
      fullName: "",
      nameOnCheck: "",
      routingNumber: "",
      accountNumber: "",
      confirmAccountNumber: "",
      agreesToTerms: false,
    },
    signatureDataUrl: null,
    dialogOpen: false,
    screenToShow: "sig",
    errors: {},
    loading: false,
  };

  clearState = () => {
    this.setState({
      data: {
        fullName: "",
        nameOnCheck: "",
        routingNumber: "",
        accountNumber: "",
        confirmAccountNumber: "",
        agreesToTerms: false,
      },
      signatureDataUrl: null,
      dialogOpen: false,
      screenToShow: "sig",
      errors: {},
      loading: false,
    });
  };

  toggleAgreesToTerms = () => {
    this.setState(function (prevState) {
      return {
        data: { ...this.state.data, agreesToTerms: !prevState.agreesToTerms },
        errors: {},
      };
    });
  };

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onChangeRoutingNumber = (e) => {
    this.setState({
      data: { ...this.state.data, routingNumber: e.target.value },
    });
  };

  routingNumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        allowLeadingZeros
        format="#########"
      />
    );
  };

  onChangeAccountNumber = (e) => {
    this.setState({
      data: { ...this.state.data, accountNumber: e.target.value },
    });
  };

  onChangeConfirmAccountNumber = (e) => {
    this.setState({
      data: { ...this.state.data, confirmAccountNumber: e.target.value },
    });
  };

  accountNumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        allowLeadingZeros
        isNumericString
      />
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      return this.handleOpenDialog();
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.agreesToTerms) errors.agreesToTerms = true;
    if (!data.fullName) errors.fullName = true;
    if (!data.nameOnCheck) errors.nameOnCheck = true;
    if (!data.routingNumber || data.routingNumber.length !== 9)
      errors.routingNumber = true;
    if (!data.accountNumber) errors.accountNumber = true;
    if (
      !data.confirmAccountNumber ||
      data.confirmAccountNumber !== data.accountNumber
    )
      errors.confirmAccountNumber = true;
    return errors;
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({
      dialogOpen: false,
      signatureDataUrl: null,
      screenToShow: "sig",
      errors: {},
    });
  };

  clearSignature = () => {
    this.sigPad.clear();
    this.clearErrors();
  };

  submitSignature = () => {
    const isSignatureBlank = this.sigPad.isEmpty();
    if (isSignatureBlank) {
      return this.setState({ errors: { signature: true } });
    }
    this.setState({
      signatureDataUrl: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });
    return this.setState({ screenToShow: "pdf" });
  };

  generateBlob = async () => {
    try {
      const { data, signatureDataUrl } = this.state;
      const { agent, publicIp } = this.props;

      const blobPdf = await pdf(
        <AchAutoPayPDF
          agent={agent}
          publicIp={publicIp}
          data={data}
          signatureDataUrl={signatureDataUrl}
        />
      );
      blobPdf.updateContainer(
        <AchAutoPayPDF
          agent={agent}
          publicIp={publicIp}
          data={data}
          signatureDataUrl={signatureDataUrl}
        />
      );

      const result = await blobPdf.toBlob();

      return result;
    } catch (error) {
      return null;
    }
  };

  submitPdfForm = async () => {
    try {
      this.setState({ loading: true });
      const { t, agent } = this.props;
      // const { data, signatureDataUrl } = this.state;
      // const { t, agent, publicIp } = this.props;
      // const blob = await pdf(
      //   <AchAutoPayPDF
      //     agent={agent}
      //     publicIp={publicIp}
      //     data={data}
      //     signatureDataUrl={signatureDataUrl}
      //   />
      // ).toBlob();

      const blob = await this.generateBlob();
      if (!blob) throw new Error();

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async function () {
        const pdfBase64data = reader.result;

        const message = {
          html: `<p>ACH Authorization Form - AgentID: ${agent.ID}</p>`,
          msg: `ACH Authorization Form - AgentID: ${agent.ID}`,
          subject: `ACH Authorization Form - AgentID: ${agent.ID}`,
          from: '"Portal V2 Email" <support@sinpin.com>',
          emailAddresses: "billing@sinpin.com",
          attachments: [
            {
              contentType: "application/pdf",
              filename: `ACH_Authorization_${agent.ID}.pdf`,
              content: pdfBase64data.replace(
                /^data:application\/(pdf);base64,/,
                ""
              ),
              encoding: "base64",
            },
          ],
        };

        await sendEmail(message);
      };
      this.clearState();
      return toast(t("toast.achFormSubmitted"), "success", "top-center", 10000);
    } catch (error) {
      const { t } = this.props;
      this.clearState();
      return toast(
        t("errors.errorSubmittingForm"),
        "error",
        "top-center",
        10000
      );
    }
  };

  renderPDF = () => {
    const { t, classes, agent, publicIp } = this.props;
    const { data, signatureDataUrl } = this.state;
    return (
      <div style={{ height: "100%" }}>
        <div className={classes.rowCenter}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<SendIcon />}
            onClick={this.submitPdfForm}
            style={{
              borderRadius: 0,
              marginTop: 8,
              marginBottom: 8,
            }}
            disabled={this.state.loading}
          >
            {`${t("buttons.submitForm")}`}
          </Button>
        </div>
        <PDFViewer style={{ height: "100%", width: "100%" }}>
          <AchAutoPayPDF
            agent={agent}
            publicIp={publicIp}
            data={data}
            signatureDataUrl={signatureDataUrl}
          />
        </PDFViewer>
      </div>
    );
  };

  renderSignaturePad = () => {
    const { t, classes } = this.props;
    return (
      <div>
        <div className={classes.sigBtnArea}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<SendIcon />}
            onClick={this.submitSignature}
            style={{
              borderRadius: 0,
              width: "100%",
            }}
          >
            {`${t("buttons.submitSignature")}`}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<CancelIcon />}
            onClick={this.clearSignature}
            style={{ borderRadius: 0, width: "100%" }}
          >
            {t("buttons.clearSignature")}
          </Button>
        </div>
        {this.state.errors.signature ? (
          <Alert variant="filled" severity="error" style={{ borderRadius: 0 }}>
            {`${t("errors.signYourName")}`}
          </Alert>
        ) : null}
        <div
          style={{ backgroundColor: "#f1f8fe", height: "100%", width: "100%" }}
        >
          <Paper className={classes.sigContainer} elevation={6}>
            <div className={classes.rowCenter}>
              <ArrowDownwardIcon
                color="primary"
                fontSize="large"
                style={{ marginTop: 16, marginRight: 8 }}
              />
              <Typography color="primary" variant="h6" align="center">
                {t("forms.signBelow")}
              </Typography>
              <ArrowDownwardIcon
                color="primary"
                fontSize="large"
                style={{ marginTop: 16, marginLeft: 8 }}
              />
            </div>
            <Divider className={classes.divider} />
            <SignaturePad
              canvasProps={{
                className: classes.sigPad,
              }}
              ref={(ref) => {
                this.sigPad = ref;
              }}
              penColor="#00457b"
              minWidth={2}
              maxWidth={3.5}
              dotSize={2.5}
              onBegin={this.clearErrors}
            />
          </Paper>
        </div>
      </div>
    );
  };

  renderDialog = () => {
    const { t, classes } = this.props;
    const { dialogOpen } = this.state;
    return (
      <Dialog
        aria-labelledby="dialog-title"
        open={dialogOpen}
        fullScreen
        TransitionComponent={SlideUp}
      >
        <AppBar className={classes.appBar} elevation={6}>
          <Toolbar>
            <Button
              variant="text"
              color="inherit"
              size="large"
              startIcon={<CloseIcon />}
              onClick={this.handleCloseDialog}
            >
              {t("buttons.cancel")}
            </Button>
            {this.state.screenToShow === "sig" ? (
              <Typography
                color="inherit"
                variant="h6"
                align="center"
                className={classes.signatureTitleText}
              >
                {t("forms.signBelow")}
              </Typography>
            ) : null}
          </Toolbar>
        </AppBar>
        {this.state.screenToShow === "sig" ? this.renderSignaturePad() : null}
        {this.state.screenToShow === "pdf" ? this.renderPDF() : null}
      </Dialog>
    );
  };

  render() {
    const { t, classes } = this.props;
    const { errors, data } = this.state;
    return (
      <div>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="fullName"
              id="outlined-fullName-input"
              label={t("forms.yourFullName")}
              variant="outlined"
              placeholder={t("forms.yourFullName")}
              error={errors.fullName}
              onFocus={this.clearErrors}
              autoFocus
              onChange={this.onChangeString}
              value={data.fullName}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="nameOnCheck"
              id="outlined-nameOnCheck-input"
              label={t("forms.nameOnCheck")}
              variant="outlined"
              placeholder={t("forms.nameOnCheck")}
              error={errors.nameOnCheck}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.nameOnCheck}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="routingNumber"
              id="outlined-routingNumber-input"
              label={t("forms.routingNumber")}
              variant="outlined"
              placeholder={t("forms.routingNumber")}
              error={errors.routingNumber}
              onFocus={this.clearErrors}
              onChange={this.onChangeRoutingNumber}
              value={data.routingNumber}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
                inputComponent: this.routingNumberFormatCustom,
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="accountNumber"
              id="outlined-accountNumber-input"
              label={t("forms.accountNumber")}
              variant="outlined"
              placeholder={t("forms.accountNumber")}
              error={errors.accountNumber}
              onFocus={this.clearErrors}
              onChange={this.onChangeAccountNumber}
              value={data.accountNumber}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
                inputComponent: this.accountNumberFormatCustom,
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="confirmAccountNumber"
              id="outlined-confirmAccountNumber-input"
              label={t("forms.confirmAccountNumber")}
              variant="outlined"
              placeholder={t("forms.confirmAccountNumber")}
              error={errors.confirmAccountNumber}
              onFocus={this.clearErrors}
              onChange={this.onChangeConfirmAccountNumber}
              value={data.confirmAccountNumber}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
                inputComponent: this.accountNumberFormatCustom,
              }}
            />
          </FormControl>
          <div className={classes.optArea}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.agreesToTerms}
                  onChange={this.toggleAgreesToTerms}
                  value="checkedB"
                  color="primary"
                />
              }
              label={
                <Typography
                  color="secondary"
                  variant="caption"
                  align="center"
                  className={classes.agreeToTermsText}
                >
                  {`${t("forms.agreeToAch")}`}
                </Typography>
              }
            />
          </div>
          {errors.agreesToTerms ? (
            <Alert variant="filled" severity="error">
              {`${t("errors.agreeToTheTerms")}`}
            </Alert>
          ) : null}
          <Divider className={classes.divider} />
          <div className={classes.btnArea}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {t("buttons.next")}
            </Button>
          </div>
        </form>
        {this.renderDialog()}
      </div>
    );
  }
}

AchAutoPayForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  publicIp: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    publicIp: state.publicIp,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(AchAutoPayForm)));
