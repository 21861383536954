import axios from "axios";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import filter from "lodash/filter";
import sanityClient from "@sanity/client";
import publicIp from "react-public-ip";
import history from "../../Utils/history";
import toast from "../../Utils/toast";
import { isTokenValid, isTokenValidDist } from "../../Utils/auth";

import {
  SANITY_PROJECT_ID,
  SANITY_DATASET,
  BASE_API_URL,
} from "../../Utils/constants";
import {
  AGENT_INFO_FETCHED,
  AGENT_USERS_FETCHED,
  COUNTRIES_FETCHED,
  PRODUCTS_FETCHED,
  CALL_PACKAGES_FETCHED,
  INVOICES_FETCHED,
  AUTO_RECHARGE_SETTINGS_FETCHED,
  SET_VOIDED_CHECK,
  SET_LANGUAGE,
  SET_APP_LOADING,
  SET_AGENT_PAGE_LOADING,
  SANITY_CALL_PACKAGES_FETCHED,
  SANITY_CARRIERS_FETCHED,
  SANITY_COUNTRIES_FETCHED,
  DAILY_PROMOS_FETCHED,
  ANNOUNCEMENTS_FETCHED,
  SET_PUBLIC_IP,
  SET_AGENT_LOGIN_DATE,
  SET_IS_CLASSIC_VIEW,
  SET_IS_DISTRIBUTOR,
  LOG_OUT,
} from "../types";

export const initialAppLoad =
  (isDist = false) =>
  async (dispatch) => {
    try {
      if (localStorage.i18nextLng) {
        dispatch(setLanguage(localStorage.i18nextLng));
      }
      if (isDist) {
        if (isTokenValidDist()) {
          return await Promise.race([
            dispatch(distributorAppLoad()),
            new Promise(function (resolve, reject) {
              setTimeout(() => {
                reject(new Error("distributortAppLoad Timed Out"));
              }, 30000);
            }),
          ]);
        }
      } else {
        if (isTokenValid()) {
          return await Promise.race([
            dispatch(agentAppLoad()),
            new Promise(function (resolve, reject) {
              setTimeout(() => {
                reject(new Error("agentAppLoad Timed Out"));
              }, 30000);
            }),
          ]);
        }
      }

      dispatch(fetchPublicIp());
      dispatch(fetchSanityInfo());
      return;
    } catch (error) {
      dispatch(setAppLoading(false));
      history.push("/");
      toast("Sorry An Error Occurred", "error");
      throw new Error("Error Loading App");
    }
  };

export const agentAppLoad = () => async (dispatch) => {
  try {
    dispatch(setAppLoading(true));
    dispatch(setLoginDate(moment()));
    dispatch(fetchPublicIp());
    dispatch(fetchSanityInfo());
    const results = await axios.get(`${BASE_API_URL}/agent/account/all`);
    if (results.data && results.data.data) {
      if (results.data.data.Agent) {
        dispatch(agentInfoFetched(results.data.data.Agent));
      }
      if (results.data.data.AgentUsers) {
        dispatch(agentUsersFetched(results.data.data.AgentUsers));
      }
      if (results.data.data.Invoices) {
        dispatch(invoicesFetched(results.data.data.Invoices));
      }
      if (results.data.data.Products) {
        dispatch(productsFetched(results.data.data.Products));
      }
      if (results.data.data.Countries) {
        dispatch(
          countriesFetched(
            filter(results.data.data.Countries, ["Active", true])
          )
        );
      }
      if (results.data.data.CallPackages) {
        dispatch(callPackagesFetched(results.data.data.CallPackages));
      }
      if (results.data.data.AutoRechargeSettings) {
        dispatch(
          autoRechargeSettingsFetched(results.data.data.AutoRechargeSettings)
        );
      }
      if (results.data.data.VoidedCheck) {
        dispatch(setVoidedCheck(results.data.data.VoidedCheck));
      }
      let classic = false;
      // deprecating classic view
      // if (
      //   results &&
      //   results.data &&
      //   results.data.data &&
      //   results.data.data.ClassicView
      // )
      //   classic = true;
      dispatch(setIsClassicView(classic));

      return dispatch(setAppLoading(false));
    }
    return dispatch(setAppLoading(false));
  } catch (error) {
    dispatch(setAppLoading(false));
    // Dont create sentry error if 401 response
    if (error.response && error.response.status !== 401) {
      Sentry.withScope((scope) => {
        scope.setLevel("fatal");
        Sentry.captureMessage("Error from agentAppLoad Function");
        Sentry.captureException(error);
      });
    }

    throw new Error("Error Loading App");
  }
};

export const distributorAppLoad = () => async (dispatch) => {
  try {
    dispatch(setAppLoading(true));
    dispatch(setLoginDate(moment()));
    dispatch(setIsDistributor(true));
    dispatch(fetchPublicIp());
    dispatch(fetchSanityInfo());
    const results = await axios.get(`${BASE_API_URL}/distributor/account/all`);
    if (results.data && results.data.data) {
      if (results.data.data.Agent) {
        dispatch(agentInfoFetched(results.data.data.Agent));
      }
      if (results.data.data.AgentUsers) {
        dispatch(agentUsersFetched(results.data.data.AgentUsers));
      }
      if (results.data.data.Products) {
        dispatch(productsFetched(results.data.data.Products));
      }

      return dispatch(setAppLoading(false));
    }
    return dispatch(setAppLoading(false));
  } catch (error) {
    dispatch(setAppLoading(false));
    // Dont create sentry error if 401 response
    if (error.response && error.response.status !== 401) {
      Sentry.withScope((scope) => {
        scope.setLevel("fatal");
        Sentry.captureMessage("Error from distributorAppLoad Function");
        Sentry.captureException(error);
      });
    }

    throw new Error("Error Loading App Distributor");
  }
};

export const agentInfoFetched = (data) => ({
  type: AGENT_INFO_FETCHED,
  data,
});

export const agentUsersFetched = (data) => ({
  type: AGENT_USERS_FETCHED,
  data,
});

export const countriesFetched = (data) => ({
  type: COUNTRIES_FETCHED,
  data,
});

export const productsFetched = (data) => ({
  type: PRODUCTS_FETCHED,
  data,
});

export const callPackagesFetched = (data) => ({
  type: CALL_PACKAGES_FETCHED,
  data,
});

export const invoicesFetched = (data) => ({
  type: INVOICES_FETCHED,
  data,
});

export const autoRechargeSettingsFetched = (data) => ({
  type: AUTO_RECHARGE_SETTINGS_FETCHED,
  data,
});

export const setVoidedCheck = (data) => ({
  type: SET_VOIDED_CHECK,
  data,
});

export const setAppLoading = (data) => ({
  type: SET_APP_LOADING,
  data,
});

export const setAgentPageLoading = (data) => ({
  type: SET_AGENT_PAGE_LOADING,
  data,
});

export const setLanguage = (data) => ({
  type: SET_LANGUAGE,
  data,
});

export const setLoginDate = (data) => ({
  type: SET_AGENT_LOGIN_DATE,
  data,
});

export const logout = () => ({
  type: LOG_OUT,
});

// Sanity.io Logic
const client = sanityClient({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET,
  useCdn: true,
});

const queryAnnouncements = `
    *[_type == "announcement" && isActive == true && displayOnResellerApp == true]
    { _id, _updatedAt, _createdAt, title, shortDescription, fullDescription, displayOnEnglishPages, displayOnSpanishPages, displayOnFrenchPages, "videoURL": videoUrl, "imageUrl": image.asset->url, "videoUrl": video.asset->url, "narrowImageUrl": narrowImage.asset->url
    }
    `;

const queryDailyPromos = `
*[_type == "dailyPromotion" && isActive == true]
{ _id, _updatedAt, _createdAt, title, shortDescription, "countries": countries[]->{name, "flagUrl": flag.asset->url}
}
`;

const querySanityCountries = `
    *[_type == "country"]
    { _id, _updatedAt, _createdAt, name, "imageUrl": flag.asset->url
    }
    `;

const querySanityCarriers = `
    *[_type == "carrier"]
    { _id, _updatedAt, _createdAt, name, "imageUrl": logo.asset->url
    }
    `;

const querySanityCallPackages = `
    *[_type == "callPackage"]
    { _id, title, id, _updatedAt, _createdAt, englishFullDescription, spanishFullDescription, frenchFullDescription, "countries": countries[]->{name, "flagUrl": flag.asset->url}
    }
    `;

export const fetchSanityInfo = () => async (dispatch) => {
  try {
    const sanityAnnouncementsPromise = client.fetch(queryAnnouncements);
    const sanityDailyPromosPromise = client.fetch(queryDailyPromos);
    const sanityCountriesPromise = client.fetch(querySanityCountries);
    const sanityCarriersPromise = client.fetch(querySanityCarriers);
    const sanityCallPackagesPromise = client.fetch(querySanityCallPackages);

    const results = await Promise.all([
      sanityAnnouncementsPromise,
      sanityDailyPromosPromise,
      sanityCountriesPromise,
      sanityCarriersPromise,
      sanityCallPackagesPromise,
    ]);
    if (results) {
      if (results[0]) {
        dispatch(sanityAnnouncementsFetched(results[0]));
      }
      if (results[1]) {
        dispatch(sanityDailyPromosFetched(results[1]));
      }
      if (results[2]) {
        dispatch(sanityCountriesFetched(results[2]));
      }
      if (results[3]) {
        dispatch(sanityCarriersFetched(results[3]));
      }
      if (results[4]) {
        dispatch(sanityCallPackagesFetched(results[4]));
      }
    }
    return;
  } catch (error) {
    return true;
  }
};

export const fetchSanityPromosAndAnnouncements = () => async (dispatch) => {
  try {
    const sanityDailyPromosPromise = client.fetch(queryDailyPromos);
    const sanityAnnouncementsPromise = client.fetch(queryAnnouncements);
    const results = await Promise.all([
      sanityDailyPromosPromise,
      sanityAnnouncementsPromise,
    ]);
    if (results) {
      if (results[0]) {
        dispatch(sanityDailyPromosFetched(results[0]));
      }
      if (results[1]) {
        dispatch(sanityAnnouncementsFetched(results[1]));
      }
    }
    return true;
  } catch (error) {
    return true;
  }
};

export const sanityAnnouncementsFetched = (data) => ({
  type: ANNOUNCEMENTS_FETCHED,
  data,
});

export const sanityDailyPromosFetched = (data) => ({
  type: DAILY_PROMOS_FETCHED,
  data,
});

export const sanityCountriesFetched = (data) => ({
  type: SANITY_COUNTRIES_FETCHED,
  data,
});

export const sanityCarriersFetched = (data) => ({
  type: SANITY_CARRIERS_FETCHED,
  data,
});

export const sanityCallPackagesFetched = (data) => ({
  type: SANITY_CALL_PACKAGES_FETCHED,
  data,
});

export const fetchPublicIp = () => async (dispatch) => {
  try {
    const ipv4 = (await publicIp.v4()) || "";
    const ipv6 = (await publicIp.v6()) || "";
    const data = { ipv4, ipv6 };
    dispatch(setPublicIp(data));
    return true;
  } catch (error) {
    return true;
  }
};

export const setPublicIp = (data) => ({
  type: SET_PUBLIC_IP,
  data,
});

export const setIsClassicView = (data) => ({
  type: SET_IS_CLASSIC_VIEW,
  data,
});

export const setIsDistributor = (data) => ({
  type: SET_IS_DISTRIBUTOR,
  data,
});
