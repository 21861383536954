import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import history from "../../Utils/history";
import startsWith from "lodash/startsWith";
import includes from "lodash/includes";
import isString from "lodash/isString";
import replace from "lodash/replace";
import ButtonBase from "@material-ui/core/ButtonBase";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import ScaleLoader from "react-spinners/ScaleLoader";
import NumberFormat from "react-number-format";
import CustomButton from "../Elements/CustomButton";
import CustomButtonMuiKit from "../Elements/CustomButtonMuiKit";
import Card from "../Elements/Card/Card";
import CardBody from "../Elements/Card/CardBody";
import CardHeader from "../Elements/Card/CardHeader";
import CustomInput from "../Elements/CustomInputMuiKit";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import ForgotPasswordDialog from "../Cards/ForgotPassword/ForgotPasswordDialog";

import loginFormStyle from "../../Utils/MaterialKitThemeJss/loginFormStyle";

import { BASE_API_URL } from "../../Utils/constants";
import { initialAppLoad } from "../../Redux/Actions/applicationActions";
import { setIsLoggedIn } from "../../Redux/Actions/authActions";
import setAuthorizationHeader from "../../Utils/auth";
import GoToAppDialog from "../Cards/Customers/GoToAppDialog";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.securityCodeInput = React.createRef();
  }
  state = {
    data: {
      username: "",
      password: "",
      securityCode: "",
    },
    require2FA: false,
    mobileLast4: "",
    forgotPasswordDialogOpen: false,
    errors: {},
    loading: false,
    showDownloadAppModal: false,
  };

  resetState = () => {
    this.setState({
      data: {
        username: "",
        password: "",
        securityCode: "",
      },
      require2FA: false,
      mobileLast4: "",
      forgotPasswordDialogOpen: false,
      errors: {},
      loading: false,
      showDownloadAppModal: false,
    });
  };

  goToUrl = (url) => {
    window.open(url);
  };

  handleToggleForgotPasswordDialog = () => {
    this.setState(function (prevState) {
      return { forgotPasswordDialogOpen: !prevState.forgotPasswordDialogOpen };
    });
  };

  handleToggleDownloadAppDialog = () => {
    this.setState(function (prevState) {
      return { showDownloadAppModal: !prevState.showDownloadAppModal };
    });
  };

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onTextChangeSecurityCode = (e) => {
    this.setState({
      data: { ...this.state.data, securityCode: e.target.value },
    });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  };

  onSubmitCredentials = async (e) => {
    e.preventDefault();
    const { t } = this.props;
    const { data } = this.state;
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      try {
        this.setState({ loading: true, errors: {} });
        const config = {
          method: "post",
          headers: {
            username: data.username,
            password: data.password,
          },
        };
        const res = await axios(`${BASE_API_URL}/auth/login`, config);
        if (!res || !res.data.success || !res.data.msg) {
          return this.setState({
            loading: false,
            errors: { login: t("errors.loginFailed") },
          });
        }
        if (startsWith(res.data.msg, "2FA Required")) {
          if (isString(res.data.msg) && includes(res.data.msg, "Sent to")) {
            const mobileLast4 = res.data.msg.substr(-4);
            this.setState({ mobileLast4 });
          }
          this.setState({ require2FA: true, loading: false });
          return this.securityCodeInput.current.focus();
        }
        if (
          !res.data.data ||
          !res.data.data.token ||
          !res.data.data.token.token
        ) {
          return this.setState({
            loading: false,
            errors: { login: t("errors.loginFailed") },
          });
        }

        // If invalid role show error
        if (
          !res.data.data.user ||
          !res.data.data.user.role ||
          res.data.data.user.role < 3
        ) {
          return this.setState({
            loading: false,
            errors: {
              login: `Invalid Role`,
            },
          });
        }

        return this.logUserIn(res.data.data);
      } catch (err) {
        return this.setState({
          loading: false,
          errors: { login: t("errors.loginFailed") },
        });
      }
    }
  };

  onSubmitSecurityCode = async (e) => {
    e.preventDefault();
    const errors = this.validateSecurityCode(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const { t } = this.props;
      const { data } = this.state;
      try {
        this.setState({ loading: true, errors: {} });
        const config = {
          method: "post",
          headers: {
            username: data.username,
            password: data.password,
            code: data.securityCode,
          },
        };
        const res = await axios(`${BASE_API_URL}/auth/login`, config);
        if (
          !res ||
          !res.data ||
          !res.data.success ||
          !res.data.data ||
          !res.data.data.token ||
          !res.data.data.token.token
        ) {
          return this.setState({
            loading: false,
            errors: { login: t("errors.loginFailed") },
          });
        }

        // If invalid role show error
        if (
          !res.data.data.user ||
          !res.data.data.user.role ||
          res.data.data.user.role < 3
        ) {
          return this.setState({
            loading: false,
            errors: {
              login: `Invalid Role`,
            },
          });
        }

        return this.logUserIn(res.data.data);
      } catch (error) {
        return this.setState({
          loading: false,
          errors: { login: t("errors.loginFailed") },
        });
      }
    }
  };

  logUserIn = async (response) => {
    const { t } = this.props;
    try {
      const token = response.token.token;
      const expires = replace(response.token.expiration, ",", "");
      const userID = response.user.UserID;
      const agentID = response.user.agent;
      const user2 = response.user.role;
      // const isClassicView = response.user.SecretQ1 === "classic";
      const isDist = user2 === 3;
      let loginPath = "/agent/dashboard";
      // if (isClassicView) loginPath = "/agent/classic/dashboard";
      if (isDist) loginPath = "/distributor/dashboard";

      LogRocket.identify(`${agentID}`, {
        name: `${this.state.data.username}`,
      });

      Sentry.setUser({
        id: agentID,
        username: this.state.data.username,
      });

      setAuthorizationHeader(token);
      this.props.setIsLoggedIn(true);

      sessionStorage.setItem("token", token);
      sessionStorage.setItem("expires", expires);
      sessionStorage.setItem("user", userID);
      sessionStorage.setItem("user2", user2);

      this.setState(
        {
          data: {
            username: "",
            password: "",
          },
          require2FA: false,
          mobileLast4: "",
          forgotPasswordDialogOpen: false,
          errors: {},
          loading: false,
        },
        async () => {
          history.push(loginPath);
          try {
            let loadDist = false;
            if (isDist) loadDist = true;
            await this.props.initialAppLoad(loadDist);
          } catch (error) {
            history.push("/");
          }
        }
      );
    } catch (err) {
      return this.setState({
        loading: false,
        errors: { login: t("errors.loginFailed") },
      });
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.username) errors.username = true;
    if (!data.password) errors.password = true;
    return errors;
  };

  validateSecurityCode = (data) => {
    const errors = {};
    if (!data.securityCode) errors.securityCode = true;
    if (data.securityCode.length < 4) errors.securityCode = true;
    return errors;
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  renderCardHeader = () => {
    const { t, classes } = this.props;
    const { require2FA } = this.state;
    return (
      <CardHeader color="success5" signup className={classes.cardHeader}>
        <h3 className={classes.cardTitle}>
          {!require2FA ? t("loginForm.resellerLogin") : t("forms.securityCode")}
        </h3>
        <div className={classes.socialLine}>
          <CustomButtonMuiKit
            justIcon
            color="transparent"
            className={classes.iconButtons}
            onClick={() => this.goToUrl("https://www.facebook.com/mysinpin/")}
          >
            <FacebookIcon />
          </CustomButtonMuiKit>
          <CustomButtonMuiKit
            justIcon
            color="transparent"
            className={classes.iconButtons}
            onClick={() =>
              this.goToUrl("https://www.instagram.com/sinpin_1click/")
            }
          >
            <InstagramIcon />
          </CustomButtonMuiKit>
          <CustomButtonMuiKit
            justIcon
            color="transparent"
            className={classes.iconButtons}
            onClick={() =>
              this.goToUrl(
                "https://www.youtube.com/channel/UC4h04-eFsJ0dN6ELBGfMeRA"
              )
            }
          >
            <YouTubeIcon />
          </CustomButtonMuiKit>
          <CustomButtonMuiKit
            justIcon
            color="transparent"
            className={classes.iconButtons}
            onClick={() => this.goToUrl("https://twitter.com/mysinpin")}
          >
            <TwitterIcon />
          </CustomButtonMuiKit>
        </div>
      </CardHeader>
    );
  };

  renderLoginForm = () => {
    const { t, classes } = this.props;
    const { loading, errors, data } = this.state;
    return (
      <Card>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmitCredentials}
        >
          {this.renderCardHeader()}
          <CardBody signup>
            <CustomInput
              name="username"
              id="outlined-username-input"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: t("loginForm.usernameLabel"),
                type: "email",
                spellCheck: "false",
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className={classes.inputIconsColor}>
                      account_circle
                    </Icon>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              value={data.username}
              error={errors.username}
              onFocus={this.clearErrors}
              autoFocus
              onChange={this.onChangeString}
              disabled={loading}
            />
            <CustomInput
              name="password"
              id="outlined-password-input"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: t("loginForm.passwordLabel"),
                type: "password",
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className={classes.inputIconsColor}>lock_utline</Icon>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              value={data.password}
              error={errors.password}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              disabled={loading}
            />
          </CardBody>
          <div className={classes.textCenter}>
            {!loading ? (
              <CustomButton
                size="small"
                style={{ marginBottom: 0, width: "55%" }}
                type="submit"
                disabled={loading}
              >
                {t("buttons.login")}
                <ArrowForward style={{ marginLeft: 8, fontSize: 20 }} />
              </CustomButton>
            ) : (
              <ScaleLoader color={"#00457b"} loading={true} />
            )}
          </div>
          {errors.login ? (
            <Alert
              variant="filled"
              severity="error"
              className={classes.alertRoot}
            >
              <Typography
                variant="h6"
                component="h2"
                className={classes.alertText}
              >
                {errors.login || t("errors.loginFailed")}
              </Typography>
            </Alert>
          ) : null}
          <div className={classes.forgotPasswordContainer}>
            <ButtonBase onClick={this.handleToggleForgotPasswordDialog}>
              <h6 className={classes.forgotPasswordText}>
                {t("loginForm.forgotPassword")}
              </h6>
            </ButtonBase>
            <ButtonBase
              // onClick={() => this.goToUrl("https://customers.sinpin.com")}
              onClick={this.handleToggleDownloadAppDialog}
            >
              <h6 className={classes.forgotPasswordText}>
                {t("links.customerLogin")}
              </h6>
            </ButtonBase>
            {/* <ButtonBase onClick={() => this.goToUrl("https://v1.sinpin.com")}>
              <h6 className={classes.forgotPasswordText}>
                {t("links.classicVersion")}
              </h6>
            </ButtonBase> */}
          </div>
        </form>
      </Card>
    );
  };

  renderSecurityCodeForm = () => {
    const { t, classes } = this.props;
    const { mobileLast4, loading, errors, data } = this.state;
    return (
      <Card style={{ paddingBottom: 16 }}>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmitSecurityCode}
        >
          {this.renderCardHeader()}
          <CardBody signup>
            <Typography
              variant="body2"
              component="h2"
              className={classes.enterCodeMessage}
            >
              {`${t("forms.enterCodeSentTo")} ${mobileLast4}:`}
            </Typography>
            <CustomInput
              name="securityCode"
              id="outlined-securityCode-input"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: t("forms.securityCode"),
                spellCheck: "false",
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className={classes.inputIconsColor}>lock_utline</Icon>
                  </InputAdornment>
                ),
                autoComplete: "off",
                inputComponent: this.NumberFormatCustom,
                inputRef: this.securityCodeInput,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              value={data.securityCode}
              error={errors.securityCode}
              onFocus={this.clearErrors}
              autoFocus
              onChange={this.onTextChangeSecurityCode}
              disabled={loading}
            />
          </CardBody>
          <div className={classes.securityCodeButtonsContainer}>
            {!loading ? (
              <CustomButton
                size="small"
                style={{ marginBottom: 0 }}
                type="submit"
                disabled={loading}
              >
                {t("buttons.submitCode")}
              </CustomButton>
            ) : (
              <ScaleLoader color={"#00457b"} loading={true} />
            )}
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={this.resetState}
              style={{ marginTop: 0, marginLeft: 8 }}
            >
              {t("buttons.cancel")}
            </Button>
          </div>
          {errors.login ? (
            <Alert
              variant="filled"
              severity="error"
              className={classes.alertRoot}
            >
              <Typography
                variant="h6"
                component="h2"
                className={classes.alertText}
              >
                {t("errors.loginFailed")}
              </Typography>
            </Alert>
          ) : null}
        </form>
      </Card>
    );
  };

  render() {
    const { require2FA } = this.state;
    return (
      <div>
        {!require2FA ? this.renderLoginForm() : this.renderSecurityCodeForm()}
        <ForgotPasswordDialog
          forgotPasswordDialogOpen={this.state.forgotPasswordDialogOpen}
          handleToggleForgotPasswordDialog={
            this.handleToggleForgotPasswordDialog
          }
        />
        <GoToAppDialog
          onClose={this.handleToggleDownloadAppDialog}
          open={this.state.showDownloadAppModal}
        />
      </div>
    );
  }
}

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
  initialAppLoad: PropTypes.func.isRequired,
};

export default connect(null, {
  setIsLoggedIn,
  initialAppLoad,
})(withTranslation()(withStyles(loginFormStyle)(LoginForm)));
